import React from "react";
import logo from '../../src/data/logos.png';

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top custom-navbar">
      <div className="container">
        <div className="navbar-header">
          <img src={logo} alt="Logo" className="navbar-logo" />
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-fond page-scroll" href="#page-top">
  Ruta Ganadera <small className="small-title">Software Ganadero</small>
</a>
        </div>
        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li><a href="#features" className="page-scroll">Características</a></li>
            <li><a href="#about" className="page-scroll">Acerca De</a></li>
            <li><a href="#services" className="page-scroll">Servicios</a></li>
            <li><a href="#testimonials" className="page-scroll">Testimonios</a></li>
            <li><a href="#team" className="page-scroll">Nuestros Planes</a></li>
            <li><a href="#contact" className="page-scroll">Contacto</a></li>

          </ul>
        </div>
      </div>
    </nav>
  );
};