import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Card as MuiCard } from '@material-ui/core';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import PlanBasico from "../data/PlanBasico.jpg"
import PlanEstandar from "../data/PlanEstandar.jpg"
import PlanPremium from "../data/PlanPremium.jpg"
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from 'react-spring';



import { CardActionArea } from '@mui/material';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Team({ resource }) {
  const [expanded, setExpanded] = React.useState(false);
  const Card = animated(MuiCard);
  const AnimatedButton = animated(Button);
  const animation = useSpring({
    from: { opacity: 0, transform: 'scale(0.8)' },
    to: { opacity: 1, transform: 'scale(1)' },
  });

  return (




    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4}>
        <Card data-aos="fade-down-left" sx={{ maxWidth: 345 }} >
          <CardActionArea>
            <CardMedia
              component="img"
              height="240"
              image={PlanBasico}
              alt="Título Plan Basico"
            />
            <CardContent sx={{ minHeight: 200 }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  color: 'primary.main', // Cambia el color del texto
                  fontWeight: 'bold', // Hace que el texto sea negrita
                  textTransform: 'uppercase', // Transforma el texto a mayúsculas
                  fontSize: '1.5rem' // Aumenta el tamaño del texto
                }}
              >
                Plan Básico
              </Typography>
              <Typography variant="h7" color="text.secondary">
                ¡Empieza tu viaje con nuestro Plan Básico! Disfruta de características increíbles a un precio asequible. Ideal para principiantes.
              </Typography>
            </CardContent>
          </CardActionArea>

          <CardActions sx={{ justifyContent: 'center' }}>
            <Button
              variant="contained"
              component="a"
              href="https://wa.me/573232825739/?text=Hola%20Equipo%20de%20Ruta%20Ganadera,%20estoy%20interesado%20en%20tomar%20el%20Plan%20B%C3%A1sico%20👋"
              target="_blank"
              startIcon={<ShoppingCartIcon />}
              style={{
                background: 'linear-gradient(45deg, #008000 30%, #006400 90%)', // Verde claro a verde oscuro
                color: 'white',
              }}
              fullWidth
              sx={{
                typography: 'button', // Esto aplica el estilo de botón predeterminado de Material-UI
                fontSize: '13px', // Esto hace que el texto sea más grande
                fontFamily: 'Roboto, sans-serif' // Esto cambia el tipo de letra a Roboto
              }}

            >
              Disfruta de un Mes Gratis
            </Button>
          </CardActions>

        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card data-aos="fade-down-left" sx={{ maxWidth: 345 }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="240"
              image={PlanEstandar}
              alt="Plan Estandar"
            />
            <CardContent sx={{ minHeight: 200 }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  color: 'primary.main', // Cambia el color del texto
                  fontWeight: 'bold', // Hace que el texto sea negrita
                  textTransform: 'uppercase', // Transforma el texto a mayúsculas
                  fontSize: '1.5rem' // Aumenta el tamaño del texto
                }}
              >
                Plan Estándar
              </Typography>
              <Typography variant="h7" color="text.secondary">
                ¡Eleva tu experiencia con nuestro Plan Estándar! Accede a más funciones y disfruta de mayor flexibilidad. Perfecto para usuarios intermedios.
              </Typography>

            </CardContent>
          </CardActionArea>

          <CardActions sx={{ justifyContent: 'center' }}>

            <Button
              component="a"
              href="https://chat.openai.com/c/b6da0dfa-9b1a-4d06-91b2-49e6ee4430ee#:~:text=Enviar%20mensaje%20para%20el%20Plan%20Est%C3%A1ndar"
              target="_blank"
              variant="contained"
              startIcon={<ShoppingCartIcon />}
              style={{
                background: 'linear-gradient(45deg, #e50914 30%, #b20710 90%)',
                color: 'white',
              }}
              fullWidth
              sx={{
                typography: 'button',
                fontSize: '13px',
                fontFamily: 'Roboto, sans-serif'
              }}
            >
              Disfruta Estándar 1 Mes Gratis
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card data-aos="fade-down-left" sx={{ maxWidth: 345 }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="240"
              image={PlanPremium}
              alt="Título de ejemplo"
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h4"
                component="div"
                sx={{
                  color: 'primary.main', // Cambia el color del texto
                  fontWeight: 'bold', // Hace que el texto sea negrita
                  textTransform: 'uppercase', // Transforma el texto a mayúsculas
                  fontSize: '1.5rem' // Aumenta el tamaño del texto
                }}
              >
                Plan Premium
              </Typography>
              <Typography variant="h7" color="text.secondary">
                ¡Lleva todo al siguiente nivel con nuestro Plan Premium! Disfruta de todas nuestras características avanzadas y obtén el máximo valor. Ideal para usuarios avanzados.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions sx={{ justifyContent: 'center' }}>

            <Button
              variant="contained"
              component="a"
              href="https://wa.me/573232825739/?text=Hola%20Equipo%20de%20Ruta%20Ganadera,%20estoy%20interesado%20en%20tomar%20el%20Plan%20Premium%20👋"
              target="_blank"
              startIcon={<ShoppingCartIcon />}
              style={{
                background: 'linear-gradient(45deg, #FFD700 30%, #DAA520 90%)', // Dorado
                color: '#000000',
              }}
              fullWidth
              sx={{
                typography: 'button', // Esto aplica el estilo de botón predeterminado de Material-UI
                fontSize: '15px', // Esto hace que el texto sea más grande
                fontFamily: 'Roboto, sans-serif' // Esto cambia el tipo de letra a Roboto
              }}

            >
              Disfruta Premium 1 Mes Gratis
            </Button>

          </CardActions>
        </Card>
      </Grid>
    </Grid>





  );
}