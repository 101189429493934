import React, { Component } from "react";
import ComponenteTeam from "./ComponenteTeam";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Nuestros Planes</h2>
          <p>
          Descubre el poder de la gestión ganadera simplificada con nuestros planes diseñados para satisfacer tus necesidades y superar tus expectativas. Desde el ganadero que recién comienza hasta el experto en la industria, tenemos el plan perfecto para ti.
          </p>
        </div>
        <div >
          <ComponenteTeam />
        </div>
      </div>
    </div>
  );
};