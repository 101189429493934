import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export const About = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      offset: 1, 
    });
  }, []);

  return (
    <div id="about" data-aos="fade-up">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6" data-aos="fade-up">
            <img src="img/about.jpg" className="img-responsive" alt="" />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text" data-aos="fade-up">
              <h2>Acerca de Nosotros</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>¿Por Qué Elegirnos?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`} data-aos="fade-up">
                            {d}
                          </li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`} data-aos="fade-up">
                            {d}
                          </li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};