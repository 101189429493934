import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

export const Services = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      offset: 200, 
    });
  }, []);

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title" data-aos="zoom-in">
          <h2>Nuestros Servicios</h2>
          <p>
          En Ruta Ganadera, estamos comprometidos a transformar la forma en que los ganaderos gestionan sus operaciones. Nuestra plataforma ofrece una suite completa de servicios diseñados para optimizar cada aspecto de tu negocio ganadero, desde la reproducción y la salud animal hasta el análisis de datos y la producción según tu propósito.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4" data-aos="zoom-in">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};  